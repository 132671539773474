
	.side-item {
		padding: $padding;
		box-shadow: none;
		border-radius: $border-radius;
		margin-bottom: $gap-size;

		.item-content {
			border-top: 2px solid white;
		}

		&.bg-blue {
			background-color: $primary-blue;
			color: white;
		}
		&.bg-green {
			background-color: $primary;
			color: white;
		}
		&.bg-white {
			background-color: white;
			color: black;
			.item-content {
				border-top: 2px solid black;
			}
		}
		&.bg-grey {
			background: #f3f3f3;
			color: black;

			.item-content {
				border-top: 2px solid black;
			}
		}
		&.bg-warning {
			background-color: $warning;
			color: black;

			.item-content {
				border-top: 2px solid black;
			}
		}
		&.bg-danger {
			background-color: $danger;
			color: white;
		}

		.item-header {
			display: flex;
			flex-wrap: nowrap;
			align-items: center;
			padding-bottom: 1rem;

			&.no-content {
				padding-bottom: 0;
			}

			.icon {
				width: 50px;
				margin-right: 1rem;
				flex-shrink: 0;
			}
			.title {
				font-weight: bold;
				font-size:1.17em;
			}
		}

		.item-content {
			padding-top: 1rem;
			.small {
				font-size: 0.8rem;
			}
			.newsletter-form {
				padding: 0;
				margin-top: 1rem;
				margin-bottom: 1rem;

				input.newsletter-signup__form__input-wrap__input {
					border-radius: 10px;
					border: none;
				}
				input.newsletter-signup__form__submit-wrap__submit-button {
					border-radius: 10px;
				}
			}

			.share-links-list {
				list-style: none;
				padding: 0;
				margin: 0;

				li {
					margin-bottom: 0.5rem;
				}
			}

		}

	}

