.article {

	padding-top: 3rem;
	background: white;
	max-width: $page-max-width;
	margin: 0 auto 3rem;

	&.bg-transparent {
		background: transparent !important;

		@include below($xl) {
			padding-left: 1rem;
			padding-right: 1rem;
		}
	}

	.article-header__content-wrap {
		max-width: $article-max-width;
	}


	.article-content {
		display: grid;
		grid-template-columns: repeat(12, 1fr);
		grid-gap: $gap-size;
		max-width: $article-max-width;
		.article-content__wrap {
			grid-column: span 8;
			grid-gap: $gap-size;
			@include below($s) {
				grid-column: span 12;
			}
		}
		.article-aside {
			grid-column: span 4;
			margin-left: 0;
			@include below($s) {
				grid-column: span 12;
			}
		}

		img[class*=wp-image-] {
			width: 100%;
			height: auto;
		}

		ul, ol {
			margin: 1rem;
			padding-left: 1rem;

			li {
				line-height: 1.56;
				margin-bottom: 0.5rem;
			}
		}

		p + ul, p + ol {
			margin-top: 0;
		}
	}
}

.article-in-short {
	margin-top: 0;

	@include below($m) {
		padding-left: 0;
		padding-right: 0;

	}
}

.article-in-short__content-row {
	padding: 0;
	&:before {
		content: none;
	}
}

.article-in-short__wrap {
	border-radius: $border-radius;
}

.default-page__page-section__header__content__heading {
	text-transform: none !important;
}

.article-related-posts {
	max-width: $page-max-width;
	margin: 0 auto 3rem;
	padding: 0 0 0 0;

	@include below($xl) {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.news-item {
		flex-basis: 33%;
	}
}

.article-related-posts__post-list {

	margin-left: -$gap-size;
	margin-right: -$gap-size;
	@include below($s) {
		margin-left: 0;
		margin-right: 0;
	}
	.row {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		gap: $gap-size;

		@include below($s) {
			flex-direction: column;

		}
	}
}

.floating-title {
	margin: 3rem 0 2rem;
	font-weight: 700;
}