@import 'vars/constants';
@import 'vars/breaker';
@import 'vars/mixins';

@import 'components/homepage/body';
@import 'components/article/body';
@import 'components/article-archive/body';
@import 'shared/newsletter-signup';
@import 'shared/site-footer';
@import 'shared/cookie-bar';


.bs-content {
    @import '../../node_modules/bootstrap/scss/bootstrap.scss';
}

@import "../../web/app/themes/trafik-goteborg/grid-layout/style.scss";

@import 'shared/various.scss';