.homepage__page-section__expandable-blocks {
	padding: 3rem 1rem;

	@include below($s) {
		padding-top: 0;
	}
	&__section-heading {
		width: 100%;
		margin: auto;
		font-size: 1.5rem;
		font-weight: 700;
		margin-bottom: 1.25rem;

		max-width: $section-max-width;
		@include below($section-max-width + ($content-padding * 2)) {
			padding: 0 $content-padding;
		}

		@include below($m) {
			text-align: center;
		}
	}
	&-wrap {
		width: 100%;
		margin: auto;
		max-width: 33.75rem; /* 540/16 */
		@include below(33.75rem + ($content-padding * 2)) { /* 540/16 */
			padding: 0 $content-padding;
		}

		@include below($s) {
			padding: 1rem 0;
		}
	}

	.expandable-content-block {
		transition: height .3s ease;
		width: 100%;
		border-top: solid 1px #aaa8a5;

		&:last-child {
				border-bottom: solid 1px #ECE9E5;
		}

		&--expanded  {
			.expandable-content-block__header__arrow-icon {
				transform: rotate(180deg) translateY(50%);
			}

			.expandable-content-block__content {
				margin-bottom: 1.5rem;
				// display: block;
				visibility: visible;
			}
			.expandable-content-block__content__holder {
				background-color: darken($white, 2%);
			}
		}
		&__header {
			padding: 2rem 0;
			cursor: pointer;
			position: relative;
			&__icon {
				display: inline-block;
				vertical-align: middle;
				margin-right: .5rem;
				max-width:4rem;
			}
			&__title {
				display: inline-block;
				font-size: 1.125rem;
				color: $black;

			}

			&__arrow-icon {
				width: 1rem;
				@include vertical-align(absolute);
				right: 0;
				transition: transform .3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
				transform-origin: center center;
			}
		}

		&__content {
			overflow: hidden;
			visibility: hidden;
			transition: height .5s cubic-bezier(0.68, -0.55, 0.265, 1.55), background-color .3s ease;
			// background-color: $white;
			font-size: 1rem;
			font-weight: 400;

			&__list {
				list-style: none;
				padding-left: 1rem;

				&.has-icon  {
					padding-left: 58px;
				}

				&-item {

					font-size: 1.25rem;
					color: $blackish;
					text-decoration: none;
					margin-bottom: 2.5rem;
					&:hover {

					}

					a {
						font-size: inherit;
						color: inherit;
						text-decoration: inherit;
						transition: inherit;
					}
					&__text {
						font-size: 1.125rem;
						display: inline-block;
						vertical-align: middle;
						text-decoration: underline;
						margin-bottom: .5rem;
					}

					&__description {
						font-size: 14px;
					}
				}
			}
			&__holder {
				padding: 1.5rem;
			}
		}
	}

}
