#wpadminbar { display: none; }
header {

	&.grid-layout {
		background: $primary-blue;

		.header-wrapper {
			max-width: $page-max-width;
			display: flex;
			flex-wrap: nowrap;
			align-items: center;
			justify-content: space-between;
			height: 90px;
		}
	}

	.logo {
		max-width: 220px;
		flex-basis: 15%;

		img {
			margin-bottom: -10px;
		}

		@include below($l) {
			flex-basis: 70%;
			width: 200px;
		}
	}

	.menu {
		margin: 0;
		padding:0;
		display: flex;
		justify-content: space-between;
		flex-basis: 55%;

		@include below($l) {
			display: none;
		}

		.menu-sub-item {
			margin: 0;
			padding: 10px 20px;
			a {
				color: white;
				font-size: 16px;

			}
			&:after {
				content: none;
			}
		}
	}

	.search {
		a {
			padding: 6px 10px;
		}
		.fa {
			font-size: 22px;
			color: white;
		}
		@include below($l) {
			display: none;
		}
	}

	.site-header__nav-trigger-col {
		@include below($l) {
			display: block;
		}
	}
	.site-navigation {
		.site-navigation__close-area {
			background: $primary-blue;
		}
		.site-navigation__menu-area {
			background: $primary-blue-dark;
		}
		.site-navigation__main-navigation__holder {
			li.menu-item a {
				color: white;
				line-height: 2;
			}
			li {
				border-bottom: 1px solid white !important;
			}
		}
	}

	.search-form {
		background: $primary-blue-dark;

		&.d-none {
			display: none;
		}

		form {
			max-width: 1280px;
			margin: 0 auto;
			display: flex;
			flex-wrap: nowrap;
			padding: 1rem 0;

			input {
				flex-basis: 80%;
				padding: 15px 30px;
				border: none;
				border-radius: 5px 0 0 5px;
				font-size: 16px;
				flex-grow: 1;

				&::placeholder {
					color: black;
				}
			}


			.search-submit {
				flex-basis: 5%;
				background: white;
				color: black;

				border: none;
				border-radius: 0 5px 5px 0;
				font-size: 16px;
				cursor: pointer;
			}
		}
	}

	.site-header__nav-trigger-col__button {
		padding: 1rem;
	}
	.nav-icon__box {
		.nav-icon__box__inner {
			background: white;
			&:before, &:after {
				background: white;
			}
		}
	}
}

.site-navigation__close-area__close-icon {
	color: white;
	fill: white;
	margin-left: 0.5rem;
	margin-right: 0.5rem;
}

.archive-page-section-header {

	.archive-page-section-header-content {
		max-width: $page-max-width;
		margin: 0 auto;
		width: 100%;
		padding-top: 3rem;

		.intro-heading {
			margin-bottom: 1rem;
		}
		.heading {
			font-size: 2.8rem;
			margin-bottom: 1rem;
			font-weight: 900;
		}
		.text {
			margin-bottom: 1rem;
		}
	}
}

