.site-footer {
	background-color: $light-beige;
	width: 100%;
	display: block;


	&__top-section {
		border-bottom: solid 2px $white;
		padding-bottom: 1.5rem;
		padding: 2rem 0 1.5rem 0;

		&__nav-column {
			@include below($s) {
				padding-bottom: 1.5rem;
				text-align: center;
			}
			.site-navigation__footer-navigation__holder {
				list-style: none;

				.menu-item {
					display: inline-block;
					vertical-align: middle;
					color: $blackish;
					font-size: 13px;
					font-weight: 400;
					&:hover {
						text-decoration: underline;
					}
					&:not(:last-child){
						margin-right: .5rem;
					}

					a {
						color: inherit;
						text-decoration: inherit;
						font-size: inherit;
					}
				}
			}
		}

		&__contact-column {

			text-align: center;
			@include above($s) {
				text-align: right;
			}

			&__text {
				color: $blackish;
				font-size: 13px;
				font-weight: 700;
				&__link {
					color: inherit;
					font-weight: 400;
					text-decoration: none;
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}



	&__bottom-section {
		padding: 2rem 0 1.5rem 0;
		@include below($s) {
			text-align: center;
		}
		&__fineprint-column {
			color: $blackish;
			font-size: 13px;
			font-weight: 400;
			@include below($s) {
				padding-bottom: 1.5rem;
			}
			&__fineprint {
				opacity: .50;
				padding-bottom: .5rem;
			}
			&__copyright {
				opacity: .50;
			}
		}

		&__social-column {
			@include above($s) {
				text-align: right;
			}
			&__social-media-item {

				display: inline-block;
				vertical-align: middle;
				width: 100%;
				max-width: 1rem;
				transition: opacity .3s ease;
				&:hover {
					opacity: .5;
				}
				&:not(:last-child) {
					margin-right: .5rem;
				}
				&__link {
						display: block;

						&_icon {
							max-width: 100%;
						}
					}
				}
			}
		}

}
