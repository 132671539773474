#grid-layout {
	padding-top: 1rem;
}

.news-item {
	border-radius: $border-radius;
	background: white;
	overflow: hidden;

	a {
		display: block;
	}
	.picture {
		width: 100%;
		margin: 0;
		padding: 0 0 56% 0;
		background: rgba(0, 0, 0, 0.1);
		display: block;
		position: relative;

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&.half-news-item {
		@include below($s) {
			grid-column: span 12;
		}
	}

	.news-text-wrapper {
		margin: 0;
		padding: $padding;

		.heading {

		}

		.excerpt {
			margin: 1rem 0 0 0;
			padding: 0;
			font-size: 16px;
		}
		time {
			font-size: 14px;
			color: rgb(31 31 31);
			font-weight: 400;
			display: flex;
			align-items: center;
			margin-top: 1rem;

			svg {
				margin-right: 0.4rem;
				width: 22px;
				margin-top: -2px;
				stroke: rgb(31 31 31);
			}
		}
	}



	.category {
		display: inline-flex;
		align-items: center;
		justify-content: start;
		margin-top: 1rem;
		border-radius: 4px;
		background: $primary;
		padding: 5px 10px;
		color: white;

		.dot {
			margin: 0 0.4rem 0 0;
			height: 10px;
			width: 10px;
			background-color: white;
			border-radius: 50%;
			display: inline-block;
			display: none;
		}
	}

	&.news-list-item {
		display: flex;
		justify-content: space-between;
		padding: 0;
		list-style: none;
		background: white;
		border-radius: $border-radius;
		margin: 0;
		overflow: hidden;
		flex-direction: row;

		.picture {
			grid-column: span 4;
			flex-basis: 350px;
			flex-shrink: 0;
			width: 350px;
			height: 210px;
			min-height: 100%;
			margin: 0;
			padding: 0;
			background: rgba(0, 0, 0, 0.1);
			display: block;
			position: relative;

			a, img {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.news-text-wrapper {
			flex-grow: 1;
		}

		&.news-item-size-large {
			.picture {
				flex-basis: 450px;
				width: 450px;
				height: 270px;
			}
		}


		@include below($s) {
			flex-direction: column;
			.picture {
				flex-basis: auto !important;
				flex-shrink: 1;
				width: 100%;
				padding-bottom: 56%;
				height: 0 !important;
				min-height: 0 !important;
			}
			.news-text-wrapper {
				grid-column: span 12;
				flex-basis: 100%;
			}
		}
	}

	&.news-category {
		.news-text-wrapper {
			border-bottom: 12px solid $primary;
		}

		&.news-category-nyheter {
			.news-text-wrapper {
				border-bottom: 12px solid $primary-blue;
			}
		}
	}
}