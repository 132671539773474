html,
body {
	margin: 0;
	padding: 0;
}

img.auto-size {
	/* width: 100%; */
	/* height: 100%; */
	height: 180px;
}

/* img
{
    text-align: center;
} */

.v-sep {
	border-right: 1px solid black;
	/* width: 100%; */
	/* width: 50%; */
	/* margin-left: 10px;
    margin-right: 10px; */
	/* text-align: center; */
	/* text-align: center; */
}

@function ieRepeat($count, $expr){
	$c: $expr;
	@for $i from 2 through $count
	{
		$c: $c $expr;
	}
	@return $c;
}

.grid-css {
	display: -ms-grid;
	display: grid;
	// -ms-grid-columns: (1fr)[12];
	-ms-grid-columns: ieRepeat(12, 1fr);
	grid-template-columns: repeat(12, 1fr);
	/* grid-auto-rows: 100%; */
}

.grid-css-tf-and-btn {
	display: -ms-grid;
	display: grid;
	-ms-grid-columns: 1fr auto;
	grid-template-columns: 1fr auto;
}

[class|="grid-col"] {
	/* display: table; */
}

.cl-2 {
	-ms-grid-column-span: 2;
	grid-column: span 2;
}
.cl-3 {
	-ms-grid-column-span: 3;
	grid-column: span 3;
}
.cl-4 {
	-ms-grid-column-span: 4;
	grid-column: span 4;
}
.cl-5 {
	-ms-grid-column-span: 5;
	grid-column: span 5;
}
.cl-6 {
	-ms-grid-column-span: 6;
	grid-column: span 6;
}
.cl-7 {
	-ms-grid-column-span: 7;
	grid-column: span 7;
}
.cl-8 {
	-ms-grid-column-span: 8;
	grid-column: span 8;
}
.cl-9 {
	-ms-grid-column-span: 9;
	grid-column: span 9;
}
.cl-10 {
	-ms-grid-column-span: 10;
	grid-column: span 10;
}
.cl-11 {
	-ms-grid-column-span: 11;
	grid-column: span 11;
}
.cl-12 {
	-ms-grid-column-span: 12;
	grid-column: span 12;
}

// .cl-pos-1 { -ms-grid-column: 1; grid-column-start: 1; }
// .cl-pos-2 { -ms-grid-column: 2; grid-column-start: 2; }
// .cl-pos-3 { -ms-grid-column: 3; grid-column-start: 3; }
// .cl-pos-4 { -ms-grid-column: 4; grid-column-start: 4; }
// .cl-pos-5 { -ms-grid-column: 5; grid-column-start: 5; }
// .cl-pos-6 { -ms-grid-column: 6; grid-column-start: 6; }
// .cl-pos-7 { -ms-grid-column: 7; grid-column-start: 7; }
// .cl-pos-8 { -ms-grid-column: 8; grid-column-start: 8; }
// .cl-pos-9 { -ms-grid-column: 9; grid-column-start: 9; }
// .cl-pos-10 { -ms-grid-column: 10; grid-column-start: 10; }
// .cl-pos-11 { -ms-grid-column: 11; grid-column-start: 11; }
// .cl-pos-12 { -ms-grid-column: 12; grid-column-start: 12; }
.cl-pos-1 { -ms-grid-column: 1; }
.cl-pos-2 { -ms-grid-column: 2; }
.cl-pos-3 { -ms-grid-column: 3; }
.cl-pos-4 { -ms-grid-column: 4; }
.cl-pos-5 { -ms-grid-column: 5; }
.cl-pos-6 { -ms-grid-column: 6; }
.cl-pos-7 { -ms-grid-column: 7; }
.cl-pos-8 { -ms-grid-column: 8; }
.cl-pos-9 { -ms-grid-column: 9; }
.cl-pos-10 { -ms-grid-column: 10; }
.cl-pos-11 { -ms-grid-column: 11; }
.cl-pos-12 { -ms-grid-column: 12; }

.fw {width: 100%;}
.v-center { align-items: center; }
.h-center { justify-items: center; }

@media screen and (max-width: 1000px) {
	/* .menu
    {
        order: 2;
    }
    .content
    {
        order: 1;
    } */
}

.header-wrapper {
	max-width: 1137px;
	margin: 0px auto;
	width: 100%;
}

.grid-layout {
background-color: white;
}

.main {
	align-self: end;
	align-items: center;
	text-align: center;
}

.balken {
	background-color: #03d141;
	font-family: Arial, sans-serif;
	padding: 1.5rem 2rem;
	text-align: center;
	transition: height 0.3s ease;
	font-size: 0.875rem;
}

.balken-type {
	font-weight: 700;
	margin-right: 15px;
}
.balken-text {
	color: white;
	font-weight: 700;
	margin-right: 15px;
}
.balken-url {
	color: white;
	text-decoration: underline;
}

.menu {	
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content:flex-start;
	align-items: space-evenly;
	margin: 2rem auto;
}

.site-header__nav-trigger-col
{
	display: none;
}

.menu-sub-item{	
	margin-top: 1rem;
}

.menu-sub-item a {

	font-size: 0.95rem;
	text-decoration: none;
	color: black;
	white-space: nowrap;
}
.menu span::after {
	content: "|";
	float: right;
	color: black;
	margin: 0 0.6rem;	
}
.menu span:last-child::after {
	display: none;
}

body {
	background-color: #f3f3f3;
}

.content {	
	padding: 0px 0px 0px 0px;
	justify-items: center;
	max-width: 1137px;
	/* width: 96%;     */
	margin: 38px auto;
	position: relative;
	/* grid-gap: 20px; */

	font-family: Helvetica, Arial, "Nimbus Sans L", "Bitstream Vera Sans",
		sans-serif;
}

.content-sect-1 {
	/* margin-left: 15px; */
	/* background-color:darkgoldenrod; */
}
// .content-sect-1 > *:last-child
// {
// 	margin-bottom: 2rem;
// }
.content-sect-2 {
	width: 100%;
	margin-left: 15px;
	align-content: start;
	/* align-content: center; */
	/* margin: 0 auto; */
	/* position: relative;     */
}

.pic {
	max-width: 100%;
	height: auto;
	position: relative;
	overflow: hidden;
}

.full-new-item {
	-ms-grid-column-span: 12;
	grid-column: span 12;
}
.half-news-item {
	-ms-grid-column-span: 12;
	grid-column: span 6;
	// margin: 10px;
}

.full-new-item .news-text-wrapper {
	padding: 16px 16px 0 0;
	margin-left: 10px;
}
.half-news-item .news-text-wrapper {
	padding: 16px 16px 0 0;
}
.full-new-item .excerpt {
	/* background-color: violet; */
	padding: 10px 0px 0px 0px;
	line-height: 1.375;
	font-size: 0.825rem;
	line-height: 1.33333;
	color: black;
	font-weight: 400;
}

.half-news-item .excerpt {
	padding: 10px 0px 0px 0px;
}

.news-top-bar {
	display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
	justify-content: start;
	
	border-color: #4ab6a2;
	border-style: solid;
	border-width: 2px 0 0 0;
	margin: 2rem 0 0 0;
    min-height: 2rem;
    /* background-color: violet; */
}
.news-top-bar span {
	
	align-self: baseline;
	margin: 0.35rem;
	vertical-align: top;
}
.full-new-item .news-top-bar,.news-list-item .news-top-bar {
	min-height: 2rem;
}
.dot {
	margin: 5px 0px 5px 0px;
	height: 10px;
	width: 10px;
	background-color: #4ab6a2;
	border-radius: 50%;
	display: inline-block;
}

.half-news-item .pic {
	// padding-bottom: 6px;
}

.news-text-wrapper h1 {
	font-size: 1.725rem;
	/* line-height: 0.01524; */
	margin-block-start: 0em;
	margin-block-end: 0em;
	margin-bottom: 4px;
	font-weight: bold;
}

.excerpt {
	line-height: 1.375;
	font-size: 0.825rem;
	line-height: 1.33333;
	color: black;
	font-weight: 400;
}

.half-news-item .excerpt {	
	padding: 0px 0px 0px 0px;	
}

.information-link a {
	font-size: 1.025rem;
    font-weight: 700;
    margin-bottom: 1rem;
    text-align: center;
    width: 100%;
	text-align: center;
	width: 100%;
	margin: auto;
	color: black;
	text-decoration: none;
}
.information-link a:hover {
	text-decoration: underline;
}

.information-link {
	text-align: center;
	margin: 1.3rem 0.6rem 0 0.6rem;
	padding: 20px;
	border: #4ab6a2;
	border-style: solid;
	border-width: 2px;
	-ms-grid-column-span: 12;
	grid-column: span 12;
	
}

/* .news-row {
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
}

.news-list {
    margin: 10 auto;    
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
} */

.news-list-item .pic {
	// min-width: 219px;
	// position: relative;
}
.news-list-item .pic:before {
	// display: block;
	// content: "";
	// width: 100%;
	// padding-top: 56.1643835616%;
}
.news-list-item .pic > img {
	// position: absolute;
	// top: 0;
	// right: 0;
	// bottom: 0;
	// left: 0;
}

.news-list-item .news-text-wrapper {
	-ms-grid-column: 5;
	-ms-grid-row: 2;
	padding-left: 10px;
}

.news-list-item .pic
{
	-ms-grid-row: 2;
}

.news-list-item {
	/* margin: 10px; */
	// padding: 10px 5px 10px 5px;
	// border-top: 2px solid #4ab6a2;
	// min-height: 20px;
	// width: auto;
	// display: flex;
	// flex-direction: row;
	// flex-wrap: nowrap;
	// flex: initial;
	// justify-content: flex-start;
	-ms-grid-column-span: 12;
	grid-column: span 12;
	// max-height: 145px;
}

// .news-list-item h1 {
// 	font-size: 1.425rem;
// 	/* line-height: 0.01524; */
// 	margin-block-start: 0em;
// 	margin-block-end: 0em;
// 	margin-bottom: 4px;
// 	font-weight: bold;
// }

.side-panel {
	/* height: 100%; */
}

.side-item .info {
	padding: 10px;
}
.info-top-bar {
	border-width: 1px;
	border-color: #4ab6a2;
	border-style: solid;
	margin: 2rem 0;
}

// .side-item-wrapper {
// 	background-color: white;
// 	-webkit-box-shadow: 10px 10px 11px -8px rgba(0, 0, 0, 0.25);
// 	-moz-box-shadow: 10px 10px 11px -8px rgba(0, 0, 0, 0.25);
// 	box-shadow: 10px 10px 11px -8px rgba(0, 0, 0, 0.25);
// }
.side-item {
	background-color: white;
	-ms-grid-column-span: 12;
	grid-column: span 12;
}
.side-item-extras {
	margin: 0px 0px 10px 0px;

	-webkit-box-shadow: 0 20px 40px -8px rgba(0,0,0,.1);
	-moz-box-shadow: 0 20px 40px -8px rgba(0,0,0,.1);
	box-shadow: 0 20px 40px -8px rgba(0,0,0,.1);

}
.facebook-item img {
	width: 20%;
}
.facebook-item {	
	// height: 100%;
	justify-content: center;
	// padding: 16px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
}

.content-sect-2 .facebook-item {
	padding: 1rem;		
}

.facebook-item p {
	white-space: wrap;
	line-height: 1.375;
	font-size: 1.225rem;
	line-height: 1.33333;
	color: black;
	font-weight: 400;
	padding-left: 16px;
}
.facebook-item a { text-decoration: none; color: black; }
.facebook-item a:hover { text-decoration: underline; }

.side-item .search-container {
	/* display: block; */
	/* margin: 0 auto; */
	padding: 0px;
	/* margin: 0;
    padding: 0; */
}

.search-container input {
	/* margin: 0 auto; */
	font-size: 2.225rem;
	/* width: 100%; */
	/* height: 45px; */
	padding: 0 20px;
	font-size: 1rem;
	border: none;
	outline: none;
	// Set placeholder color
	&::-webkit-input-placeholder {
		color: black;
	}
}

.search-icon {
	/* position: relative; */
	/* float: right; */
	/* width: 75px; */
	/* height: 75px; */
	/* top: -62px; */
	/* right: -15px; */
}

.news-letter {
	/* padding: 20px; */
}

.news-letter img {
	padding: 20px 20px 0px 20px;
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 80%;
}

.newsletter-signup-heading {
	font-size: 1.675rem;
	font-weight: 700;
	margin-bottom: 1rem;
	text-align: left;
	width: 100%;

	&:after {
		content: "";
		display: block;
		width: 100%;
		height: 2px;
		margin-top: 1rem;
		background: linear-gradient(270deg, #4ab6a2 0%, #007bc7 100%);
	}
}

.newsletter-signup-wrap {
	text-align: left;
	// padding: 16px 16px 0px 16px;
}

.content-sect-2 .newsletter-signup-wrap {
	padding: 16px 16px;
}

.newletter-signup-sub-heading {
	font-size: 1.125rem;
	font-weight: 400;
	margin-bottom: 1rem;
	text-align: left;
	width: 100%;
	margin: auto;
	max-width: 620px;
}
.newsletter-form {
	max-width: 680px;
	padding-top: 2rem;
	margin: auto;
	text-align: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
}
.newletter-input-wrap {
	text-align: center;
	display: inline-block;
	vertical-align: middle;
	width: 100%;
	grid-column: span 12;
}
.newletter-input-wrap input {
	width: 100%;
	margin: auto;
	padding: 1.12rem;
	background-color: #f1f2f2;
	color: #0d0d0d;
	border: 1px solid #3b3b3b;
	font-weight: 700;
	font-size: 0.875rem;
	-webkit-transition: background-color 0.3s ease;
	-o-transition: background-color 0.3s ease;
	transition: background-color 0.3s ease;
	&::-webkit-input-placeholder {
		color: black;
		font-weight: normal;
	}

	&:focus {
		background-color: #f9fafa;
	}
}

.newletter-submit-wrap {
	width: 100%;
	grid-column: span 12;
	display: inline-block;
	vertical-align: middle;
	text-align: center;
}
.newletter-submit-wrap input {
	border: 0;
	background-color: #3b3b3b;
	color: #fff;
	margin: auto;
	display: inline-block;
	padding: 1.25rem 1.5rem;
	text-transform: uppercase;
	font-size: 0.75rem;
	font-weight: 700;
	-webkit-transition: background-color 0.3s ease, opacity 0.3s ease;
	-o-transition: background-color 0.3s ease, opacity 0.3s ease;
	transition: background-color 0.3s ease, opacity 0.3s ease;
	height: 100%;
	max-height: 56px;
	width: 100%;
	margin-top: 0.5rem;
}

.newsletter-singup-form__response-message-holder
{
	width: auto;
}

.quick-link-items {
	background-color: transparent;
	/* margin: 0px 0px 10px 0px; */
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	flex: initial;
	justify-content: flex-start;
}
.quick-link-wrapper {
	margin: 0px 0px 6px 0px;
}

.quick-link-item {
	padding: 0 10px;
	// margin-left: 20px;
}
.content-sect-2 .quick-link-item {
	padding: 10px;
	margin-left: 20px;
}
.quick-link-item h3 {
	margin: 0;
	font-family: SegoeUI;
	font-size: 1.675rem;
	font-style: normal;
	font-variant: normal;
	font-weight: 700;
	line-height: 40.7px;
}
.quick-link-item h3 a
{
    text-decoration: none;
    color: black;
}
.quick-link-item h3 a:hover { text-decoration: underline; }

.quick-link-item p {
	margin: 6px 0px 0px 0px;
}
@font-face {
	font-family: SegoeUI;
	src: local("Segoe UI Bold"),
		url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.woff2)
			format("woff2"),
		url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.woff)
			format("woff"),
		url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.ttf)
			format("truetype");
	font-weight: 600;
}

.footer {
	flex: 0 0 auto;
	background-color: #1e1e1e;
	color: white;
	padding-top: 30px;
	padding-bottom: 30px;
	position: relative;
}
.footer a {
	line-height: 2;
	color: white;
}
.footer ul {
	list-style: none;
	padding-left: 0;
}
.footer .footer-title {
	font-size: 16px;
	font-weight: bold;
	margin-bottom: 1rem;
	display: block;
}

.footer {
	.grid-css {
		.footer-img-link {
			text-align: center;
			margin-left: auto;
			margin-right:auto;
		}
		.footer-img {
			max-width: 300px;
			height: auto;
			margin-bottom: 2rem;

		}

	}
	.footer-divider {
		border-bottom: 1px solid #515151;
		width: 100%;
		margin-bottom: 3rem;
	}
}

.footer-list {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;	
	margin: 1em 0;
}
.footer-container {
	.content {
		justify-items: left;

		h4 {
			margin-bottom: 1rem;
		}
	}
}
.grid-css {
	.footer-list {
		display: block;
		text-align: left;
		margin: 0;

		.social-media-item__link {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
		}

		.social-media-item__link__icon {
			max-width: 50px;
			margin-right: 10px;
		}

	}
}

.newsletter-signup-wrap__footer {
	text-align: left;
	* {
		text-align: left;
	}
	.newletter-signup-sub-heading {
		font-size: 14px;
	}
	.newsletter-form {
		padding: 1rem 0;
	}


}

.footer-list-item {
	font-family: Arial, sans-serif;
	margin: 0 25px 0 0;

	@include below($m) {
		margin: 0 0 0 0;
	}
}

.footer-container {
	width: 100%;
	padding-right: 10px;
	padding-left: 10px;
	margin-right: auto;
	margin-left: auto;
}

.footer-wrapper img {
	max-width: 350px;
	width: 100%;
}
.pic img {
	// position: absolute;
	// bottom: 0;
	// width: 100%;
	// height: auto;
}
.full-new-item .campaign {
	// border-top: 1.3rem solid #f5f5f5;
}

.campaign-pic {
	/* position: absolute;
	bottom: 0; */
	width: 100%;
	height: auto;
}
.content-sect-1 .news-letter img {
	width: 40%;
}

/* Mobile */
@media only screen and (max-width: 768px) {


	.campaign {
		-ms-grid-column-span: 12;
		grid-column: span 12;
	}

	.content{
		margin: 0 auto;
	}

	.footer-container {
		.cl-4 {
			width: 100%;
			grid-column: span 12;
			margin-bottom: 2rem;
			padding-top: 2rem;
			border-top: 1px solid #515151;
			order: 3;

			&:first-child {
				order: 2;
			}

			&:last-child {
				order: 1;
				padding-top:0;
				border-top: none;
			}
		}
		.footer-divider {
			margin-bottom: 2rem;
		}
		.newsletter-form {
			max-width: 100%;
			.grid-css-tf-and-btn {
				@include above($m) {
					display: flex;
				}
				//
			}
		}
		.newletter-signup-sub-heading {
			max-width: 100%;
		}
		.newletter-input-wrap {
			grid-column: span 8;
		}
		.newletter-submit-wrap {
			padding: 0;
			grid-column: span 4;
		}
		.content {
			padding: 0 1rem;
			justify-items: left;

			h4 {
				margin-top: 2rem;
				margin-bottom: 1rem;
			}
		}
	}

	.footer-list-item {
		margin: 0px;
	}
	.footer-list-item a {
		white-space: nowrap;		
	}

	.footer-wrapper img {
		width: 50%;
	}

	.news-letter img {
		width: 60%;
	}

	.newsletter-form{
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-flow: column nowrap;
		flex-flow: column nowrap;
	}

	.newletter-submit-wrap {
		padding: 10px;
		-ms-grid-column-span: 12;
		grid-column: span 12;
	}
	.newletter-input-wrap
	{
		-ms-grid-column-span: 12;
		grid-column: span 12;
	}

	.newsletter-signup-wrap{
		// padding-top: 50px;
		/* border: #4ab6a2;
		border-width: 2px 0px 0px 0px;
		border-style: solid; */
	}

	.facebook-item {
		/* padding: 5px; */
		// border: #4ab6a2;
		// border-width: 2px 0px 0px 0px;
		// border-style: solid;
		-ms-grid-column-span: 12;
		grid-column: span 12;
	}
	.desktop-only {
		display: none;
	}
	.content-sect-1 {
		-ms-grid-column-span: 12;
		grid-column: span 12;
		padding: 0 .6rem .6rem .6rem;
	}

	.content-sect-2 {
		display: none;
	}
	.content-item {
		-ms-grid-column-span: 12;
		grid-column: span 12;
	}
}

/* Desktop */
@media only screen and (min-width: 769px) {
	.mobile-only {
		display: none;
	}
	.alert-bar {display: block;}

	.half-item
	{
		&-1 > :not(.news-top-bar) { margin-right: 5px; }
		&-2 > :not(.news-top-bar) { margin-left: 5px; }
	}
}

/* Pad */
@media only screen and (min-width: 769px) and (max-width: 1171px) {
	#grid-layout
	{
		margin-left: 0.5rem;
	}
}
.hamburger .line{
	width: 50px;
	height: 5px;
	background-color: #ecf0f1;
	display: block;
	margin: 8px auto;
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
  }

  .hamburger:hover{
	cursor: pointer;
  }

.hamburger .is-active .line:nth-child(1),
.hamburger .is-active .line:nth-child(3){
  width: 40px;
}

.hamburger .is-active .line:nth-child(1){
  -webkit-transform: translateX(10px) rotate(45deg);
  -ms-transform: translateX(10px) rotate(45deg);
  -o-transform: translateX(10px) rotate(45deg);
  transform: translateX(10px) rotate(45deg);
}

.hamburger .is-active .line:nth-child(3){
  -webkit-transform: translateX(10px) rotate(-45deg);
  -ms-transform: translateX(10px) rotate(-45deg);
  -o-transform: translateX(10px) rotate(-45deg);
  transform: translateX(10px) rotate(-45deg);
}



.logo {
	width: 80%;
	margin: 1.2rem 0.5rem;
	align-self: center;
}
html
{
    font-size: 16px;
	// font-size: 1vw;
}

.footer-img
{
    justify-self: center;
}

a { color: black; text-decoration: none; cursor: pointer; } a:hover { text-decoration: underline; }

.sticky
{
	// position: sticky;
	position: fixed;
}
#sticky-header
{
	z-index: 10000;
}
#sticky-sidebar
{
	// display: relative;
	z-index: 9999;
}
// .clickable-menu-item ul
// {
// 	display: none;
// }
#blue-menu
{
	// margin-top:5px;
	top: 5px;
	// min-width: 720px;
	max-width: 900px;
	position: relative;
	display: none;
}
.blue-menu-item
{
	display: inline-block;
	padding:0.8em;
	font-size: 0.75rem;
	-ms-grid-column-span: 1;
	grid-column: span 1;
	cursor: pointer;
}
.blue-menu-top
{
	background-color: white;
	margin: 0 auto;
	text-align: center;
	width: 100%;
	position: absolute;
    z-index: 10001;
}
.blue-menu-bottom
{
	/* autoprefixer grid: autoplace */
	color: white;
	padding: 15px;
	display: -ms-grid;
	display: grid;
	-ms-grid-columns: ieRepeat(3, 1fr);
	-ms-grid-rows: ieRepeat(3, 1fr);
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(3, 1fr);
	background-color: #0170BF;
	width: 100%;
	position: absolute;
	z-index: 10000;
	text-align: left;
}
.blue-menu-bottom-item
{
	/* autoprefixer grid: autoplace */
	margin-top: 20px;
	-ms-grid-column-span: 1;
	// -ms-grid-row: 1;
	grid-column: span 1;
	grid-row: span 1;
	padding: 10px;
}
.blue-menu-link a
{
	text-decoration: underline;
	color: white;
}
.blue-menu-descr
{
	font-size: 0.7rem;
}
.blue-menu-selected
{
	background-color: #0170BF;
    color: white;
}
.hidden-item
{
	display: none;
}

// #sticky-header+*
// {
// 	padding-top: 0px;
// }

// Sizes
$border-radius : 12px;
$gap-size : 1.2rem;
$padding : 1.5rem;


// Colors
$primary : #258362;
$primary-blue: #007bc7;
$primary-blue-dark: #005a8c;
$warning : #ffce00;
$danger : #d74114;

$page-max-width: 1280px;
$article-max-width: 1000px;


@import "components/general";
@import "components/header";
@import "components/frontpage";
@import "components/aside";
@import "components/archive";
@import "components/article";
@import "components/expandable-links";