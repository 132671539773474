.pagination {
	width: 100%;
	margin: auto;

	padding-bottom: 1.25rem;
	padding-top: 2rem;

	max-width: $section-max-width;
	@include below($section-max-width + ($content-padding * 2)) {
		padding: 2rem $content-padding;
	}
	&__holder {
		text-align: center;
		list-style: none;
	}
	&__page-number-holder {
		list-style: none;
		margin: auto;

		&__item {
			display: inline-block;
			font-size: 1rem;
			color: $blackish;
			background-color: $lighter-gray;
			vertical-align: middle;
			line-height: 1;
			text-decoration: none;

			&:not(:last-child) {
				margin-right: .5rem;
			}

			a {
				display: block;
				padding: 10px 15px;
				color: inherit;
				text-decoration: inherit;
			}

			&--next {
				.triangle-icon-black--right {
					max-width: 10px;
					max-height: 16px;
					width: 100%;
					&__rectangle-icon {

					}
				}
			}
			&--prev {
				.triangle-icon-black--right {
					transform: rotate(180deg);
					max-width: 10px;
					max-height: 16px;
					width: 100%;
					&__rectangle-icon {

					}
				}
			}
		}

		&__number-item {

			&:hover {
				background-color: darken($lighter-gray, .2%);
				color: $green;
			}
			a {

			}
			&--current {
				background: $green;
				color: white;

				&:hover {
					background-color: darken($green, .2%);
					color: white;
				}
			}
		}
	}


}
