.article-list {
	display: grid;
	grid-gap: 1rem;

	time {
		img {
			max-width: 22px;
			margin-right: 10px;
		}
	}
}

.archive-page-section-wrap {
	max-width: $page-max-width;
	margin: 0 auto;
	padding: 2rem 0;

}
.archive-page-section-header {
	@include below($xl) {
		padding-top: 1rem !important;
	}
}
.archive-page-section-header-content {
	@include below($xl) {
		max-width: 100% !important;
		padding: 0 1rem !important;
	}
}
.archive-search-field,
.archive-search-field-form {
	@include below($xl) {
		max-width: 100% !important;
	}
}
.archive-page-filter-section {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 2rem;
	margin-bottom: 1rem;

	.archive-search-field {
		display: flex;
		align-items: center;

		.archive-search-field-form {
			.archive-search-field-form-input-wrap {
				display: flex;
				border-radius: 20px;
				background: white;
				align-items: center;
				padding-right: 20px;
				.archive-search-field-form-icon {
					margin-right: 10px;
					width: 20px;
					margin-left: 10px;
				}
				svg {
					margin-right: 10px;
					width: 20px;
					margin-left: 10px;
				}

				.archive-search-field-form-input {
					border: none;

					padding: 8px 16px 8px 2px;
					font-size: 16px;
					width: 390px;
					max-width: 80%;
				}
			}
		}
	}
}
