.homepage__page-section__expandable-blocks {
	padding: 0;
	margin: 3rem 0;
}
.homepage__page-section__expandable-blocks-wrap {
	max-width: 100%;
	display: flex;
	margin: 0 0 3rem 0;
	@include below($m) {
		display: block;
	}


	.col {
		width: 50%;
		margin: 0 1rem;

		@include below($m) {
			width: 100%;
			flex-basis: 100%;
			margin: 0;
		}

	}

	.expandable-content-block {
		border: none;
		margin-bottom: 1rem;
	}

	.expandable-content-block__header {
		background: $primary-blue;
		border-radius: $border-radius;
		padding: 1rem 2rem 1rem 1rem;
		display: flex;
		align-items: center;

		img {
			max-width: 60px !important;
			margin-right: 2rem;
		}


		.expandable-content-block__header__title {
			color: white;
			font-weight: 700;
		}
		.expandable-content-block__header__arrow-icon {
			right: 2rem;
			svg {
				border: none;
				width: 20px;
				margin-right: 0;
			}
		}
	}

	.expandable-content-block__content__list {
		padding: 2rem !important;
		margin-top: 1rem;
		background: white;
		border-radius: $border-radius;

		.expandable-content-block__content__list-item {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}