.newsletter-signup {
	padding: 3rem 1rem 0 1rem;
	width: 100%;
	position: relative;
	// border: solid 3px $light-beige;
	background-color: #F9F9FB;
	&-wrap {
		width: 100%;
		margin: auto;
		font-size: 1.5rem;
		font-weight: 700;

		max-width: $section-max-width;
		@include below($section-max-width + ($content-padding * 2)) {
			padding: 0 $content-padding;
		}

		@include below($m) {
			text-align: center;
		}
	}
	&__heading {
		font-size: 1.625rem; /* 26/16 */
		font-weight: 700;
		margin-bottom: 1rem;
		text-align: center;
		width: 100%;
	}

	&__sub-heading {
		font-size: 1.125rem;
		font-weight: 400;
		margin-bottom: 1rem;
		text-align: center;
		width: 100%;
		margin: auto;
		max-width: 620px;
	}

	&__form {
		max-width: 680px;
		padding-top: 2rem;
		margin: auto;
		text-align: center;
		display: flex;
		flex-flow: row wrap;

		&__input-wrap {
			margin-right: .5rem;
			text-align: center;
			display: inline-block;
			vertical-align: middle;
			width: auto;
			flex: 1 1 auto;

			@include below($m) {
				max-width: 74%;
				margin-bottom: 1rem;
			}

			@include below($s) {
				max-width: 100%;
				margin-bottom: 1rem;
			}

			&__input {
				width: 100%;
				margin: auto;
				padding: 1.25rem;
				background-color: $lighter-gray;
				border: 0;
				color: $black;
				font-weight: 700;
				font-size: 0.875rem; /* 14/16 */
				transition: background-color .3s ease;
				&:focus {
					outline: 0;
					background-color: darken($lighter-gray, 10%);
				}
			}
		}

		&__submit-wrap {
			display: inline-block;
			vertical-align: middle;
			text-align: center;
			@include below($m) {
				max-width: 24%;
				width: 100%;
			}
			@include below($s) {
				max-width: 100%;
				margin-bottom: 1rem;
			}
			&__submit-button {
				border: 0;
				background-color: $blackish;
				color: $white;
				margin: auto;
				display: inline-block;
				padding: 1.25rem 1.5rem;
				text-transform: uppercase;
				font-size: 0.75rem; /* 12/16 */
				font-weight: 700;
				transition: background-color .3s ease, opacity .3s ease;
				height: 100%;
				max-height: 56px;
				&:disabled {
					opacity: .5;
					pointer-events: none;
				}
				&:hover {
					background-color: darken($blackish, 4%);
				}
			}
		}
	}

	&__image {
		width: 100%;
		margin: auto;
		max-width: 320px;

		@include below($m) {
			margin-top: 3rem;
			max-width: 220px;
		}
	}
}

.newsletter-singup-form__response-message-holder {
	width: 100%;
}
.newsletter-singup-form__response-message {

	text-align: center;
	font-size: .875rem;
	padding: .875rem;
	&--success {
		color: $dark-green;
	}
	&--fail {
		color: $orange;
	}
}

.contact-page__form-section {
	input:not([type="submit"]),
	textarea {
		background-color: $lighter-gray !important;
		border: 1px solid $black !important;
		color: $black !important;
	}
}
