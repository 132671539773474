#cookie-law-info-bar {
	width: 640px !important;
}

.default-page__page-section__content__text-content > #cookie-law-info-again {
	display: none;
}

#not-in-use.cookie-bar {
	background-color: $green;
	background-image: linear-gradient(90deg, $green 0%, $blue 100%);
	position: fixed;
	bottom: 0;
	left: 0;
	padding: 1.5rem;
	width: 100%;
	z-index: 99999999;
	transition: transform .4s ease-in, opacity .2s linear .2s;

	&--hidden {
		transform: translateY(100%);
		opacity: 0;
	}

	&-wrap {
		max-width: 920px;
		margin: auto;
			@include below($s) {
				text-align: center;
			}
	}

	&__text {
		max-width: 73%;
		display: inline-block;
		vertical-align: middle;
		font-size: 12px;
		color: $white;
		p {
			max-width: 80%;
			font-weight: 300;
			a {
				color: $white;
				font-weight: 700;
			}
		}

		@include below($s) {
			display: block;
			width: 100%;
			max-width: 90%;
			margin: auto;
			text-align: center;
			p {
				max-width: 100%;
				padding-bottom: 1rem;
			}
		}
	}

	&__button {
		max-width: 25%;
		display: inline-block;
		vertical-align: middle;
		border: 0;
		background-color: $green;
		padding: .5rem 1rem;
		transition: background-color .3s linear;
		margin: auto;
		font-weight: 700;
		color: $white;
		border-radius: 4px;

		@include below($s) {
			width: auto;
			margin: auto;
			max-width: 175px;
		}
		&:hover {
			background-color: darken($green, 5%);
		}
	}

}
