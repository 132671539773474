
// Gone is the desktop, table and mobile. Let's use s, m, l, xl.
$xs: 321px;
$s: 500px;
$m: 800px;
$l: 1050px;
$xl: 1410px;

// Default content settings.
$content-padding: 1rem;
$content-max-width: 82.5rem;
$article-content-max-width: 46.563rem; /* 745/16 */

$section-max-width: 62.5rem; /* 1000/16 */

$article-content-large-max-width: $section-max-width;


// Font weights.
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;


//$green: #4AB6A2;
$green: #258362;
//$blue: #288DC1;
$blue: #007bc7;
//$darker-blue: #0E6694;
$darker-blue: #00629e;
$link-color: $darker-blue;

$light-cyan: #00CAE8;
$dark-cyan: #00A0E8;


$light-blue: #0083E8;
$dark-blue: #0032E8;

$light-green: #3FBA74;
$dark-green: #63DB97;

$light-yellow: #FFE070;
$dark-yellow: #FFCD1C;

$light-purple: #C900E8;
$dark-purple: #9600E8;

$light-orange: #FC9170;
$dark-orange: #EE734D;
$orange:#FF5900;
$map: (
    key: value,
    nextkey: nextvalue
);
$gradients: (
	blue: ( $light-blue, $dark-blue ),
	green: ( $light-green, $dark-green ),
	cyan: ( $light-cyan, $dark-cyan ),
	orange: ( $light-orange, $dark-orange ),
	purple: ( $light-purple, $dark-purple ),
	yellow: ( $light-yellow, $dark-yellow )
);
// 	(blue,  (light, $light-blue) (dark, $dark-blue) )
// 	);
//$colorArray: (blue, $blue) (turqoise, $turqoise) (orange, $orange) (green, $green)(purple, $purple) (darkGreen, $darkGreen) (lightGreen, $lightGreen) (mediumGreen, $mediumGreen);

/*

#0083E8
#0032E8

/*
#FFE070
#FFCD1C

#3FBA74
#63DB97

#00CAE8
#00A0E8

#FC9170
#EE734D

background-image: linear-gradient(-134deg, #63DB97 0%, #3FBA74 100%);
box-shadow: 0 20px 40px 0 rgba(0,0,0,0.10);
border-radius: 10px;
 */

$light-beige: #F5F2EE;
$dark-beige: #868482;
$lightest-gray: #F1F1F1;

$lighter-gray: #F1F2F2;
$light-gray: #AAAAAA;
$medium-gray: #BBBBBB;
$gray: #BFBFBF;
$dark-gray: #666666;
$blackish: #3B3B3B;
$black: #0D0D0D;
$white: #fff;
