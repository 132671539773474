#grid-layout {
	.content {
		max-width: $page-max-width;
		gap: $gap-size;

		.content-sect-1 {
			grid-column: span 9;
			grid-gap: $gap-size;

			@include below($m) {
				grid-column: span 12;
			}
		}
		.content-sect-2 {
			grid-column: span 3;
			margin-left: 0;

			@include below($s) {
				grid-column: span 12;
			}
		}
	}
}

.page-content-holder {
	background: none !important;
	padding: 0;
}


.contact-page__form-section input:not([type=submit]),
.contact-page__form-section textarea {
	border-radius: $border-radius;
}

.wpcf7-form .wpcf7-submit {
	background: $primary-blue;
	border-radius: $border-radius;
	color: white;
	font-weight: 700;
	padding: 1rem 2rem;
	transition: ease-in-out 0.2s;

	&:hover {
		background: $primary-blue-dark;
	}
}

.btn-read-more {
	background: $primary-blue;
	border-radius: $border-radius;
	padding: 1rem 2rem;
	color: white;
	font-weight: 700;
	white-space: nowrap;
	// Align the button in center within the grid
	margin: 0 auto;
	display: block;
	transition: ease-in-out 0.2s;
	grid-column: span 12;
}

.contact-page__content-holder {
	padding-top: 1rem;
	padding-bottom: 1rem;
}
.default-page__page-section__content__text-content {
	@include below($xl) {
		max-width: 100% !important;
		padding: 0 1rem !important;
	}
	.default-page__page-section__header__content__heading {
		padding: 0 !important;
	}
}
.default-page__page-section__header__content__heading {
	@include below($xl) {
		max-width: 100% !important;
		padding: 0 1rem !important;
	}
}
.contact-page__content-holder {
	.default-page__page-section__content__text-content {
		@include below($xl) {
			max-width: 100% !important;
			padding: 0 !important;
		}
	}
}
.default-page__page-section__content-wrap {
	padding-top: 0;
	padding: 0;
}
.default-page__page-section__content__text-content {
	padding-top: 0;
}