.article-content {
	max-width: $article-content-max-width;
	margin: auto;
	@include below($article-content-max-width + 2rem) {
		padding: 0 1rem;
	}
	&-wrap {
		//
	}

	&-block {
		padding-bottom: 1.5rem;
		&__text-block {
						h1,
						h2,
						h3,
						h4,
						h5 {
							color: $black;
							font-weight: 700;
						}

						p {
							padding-bottom: 1rem;
						}

						p,
						span {
							font-size: 1rem;
							line-height: 1.56;
							color: $blackish;

							@include below($s) {
								word-break: keep-all;
								hyphens: none;
							}
							font-weight: 400;

							&.mejs-duration,
							&.mejs-currenttime {
								color: white;
								font-size: 0.8rem;
								line-height: 10px;
							}
						}

						a {
							color: $blue;
							line-height: 1.55;
							font-weight: 400;
							transition: color .4s ease;

							&:hover {
								color: darken($blue, 10%);
								text-decoration: none;
							}
						}

						ol,
						ul
						{
							color: $blackish;
						}
					}

			&__quote-block {

				text-align: center;

				&__quote {
					font-size: 2rem;
					font-weight: 700;
					color: $green;

					background: linear-gradient(90deg, $green 0%, $blue 100%);
				 -webkit-background-clip: text;
				 -webkit-text-fill-color: transparent;

				 // SORRY IE-users
				 @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
					    background: none;
					}
					@include below($s) {
						font-size: 1.5rem;
					}
				}
			}
			&__image-block {
				display: block;
				&__image {
					display: block;
				}
			}
			&__youtube-block {

				.youtube-player-placeholder {
					position: relative;
					padding-bottom: 56.25%; /* 16:9 */
					height: 100%;
					max-width: 100%;
					width: 100%;
					background-color: $black;
					background: linear-gradient(90deg, $green 0%, $blue 100%);
					&__inner-wrap,
					iframe {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						transition: opacity .3s ease;
						z-index: 999;
					}
				}


			}

			&__link-block {

				&__wrap {

					&__link {
						display: block;
						padding: 2rem 1rem;
						background-color: $green;
						background: linear-gradient(90deg, $green 0%, $blue 100%);
						background-size: 200% 100%;
						background-position: 50%;
						color: $white;
						text-decoration: none;
						transition: background-position .4s linear;
						&:hover {
							background-position: 100%;

							.article-content-block__link-block__wrap__link__text__arrow-icon {
								opacity: 1;
								transform: translateX(0);
							}
						}
						&__heading {
							font-weight: 400;
							margin-bottom: .25rem;
						}
						&__text {
							color: $white;
							font-size: 1.5rem;
							font-weight: 700;

							&__arrow-icon {
								width: 1.5rem;
								display: inline-block;
								vertical-align: middle;
								margin-left: 2rem;
								opacity: 0;
								transform: translateX(-1rem);
								transition: opacity .2s ease .1s, transform .4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
							}
						}

					}
				}
			}

	}

}

.article-byline {

	margin: 3rem auto;
	width: 100%;
	max-width: $article-content-max-width;
	@include below($article-content-max-width + 2rem) {
		padding: 0 1rem;
	}
	// &__arrow {
	// 		width: 0;
	// 		height: 0;
	// 		margin: auto;
	// 		border-left: 21px solid transparent;
	// 		border-right: 21px solid transparent;
	//
	// 		border-bottom: 28px solid $lighter-gray;
	// }
	//
	&__wrap {
		max-width: 100%;
		margin: auto;
		padding: 2.5rem 0; /* 40/16 */
	}
	&__divider {
		display: block;
		width: 100%;
		height: 5px;
		background-color: $blue;
	}
	&__header {
		margin-bottom: 1rem;

		&__heading {
			color: $green;
			font-weight: 700;
			font-size: 24px;
			letter-spacing: .5px;
		}

		&:after {
			content: "";
			display: block;
			width: 70px;
			height: 5px;
			background-color: $blue;
			margin: 1.125rem 0 1.5rem 0;
		}
	}

	&__image-wrap {
		display: inline-block;
		vertical-align: middle;
		border-radius: 48px;
		overflow: hidden;
		margin-right: 1rem;

		&__image {
			display: inline-block;
			vertical-align: middle;
			max-width: 48px;
		}
	}
	&__content {

		display: inline-block;
		vertical-align: middle;
		line-height: 1;

		&__name {
			font-size: 0.75rem; /* 12/16 */
			font-weight: 700;
			color: $dark-gray;
				text-transform: uppercase;
		}
		&__email {
			font-size: 0.75rem; /* 12/16 */
			text-decoration: none;
			color: black;
			font-weight: 700;
			margin-left: 1rem;
		}

	}
}

.article-related-posts {
	max-width:$article-content-large-max-width ;
	margin: auto;
	@include below($article-content-large-max-width + 2rem) {
		padding: 0 1rem;
	}
	.row,
	.flex-row {
		padding: 0;
	}
	&__header {
		margin-bottom: 2rem;


		&__holder {

			border:solid 1px $lightest-gray;
		}

		&__text {
				padding: 10px;
			font-size: 0.75rem; /* 12/16 */
			font-weight: 700;
			color: $green;
			text-transform: uppercase;
		}
	}

	&__post-list {

		&__post {
			margin-bottom: 1.25rem;

			&-link {
				display: block;
				height: 100%;
				text-decoration: none;
				color: $black;
				display: flex;
				flex-flow: column;

				&:hover {
					text-decoration: underline;
				}
			}
			&-image {
				position: relative;
				z-index: 1;
				background-color: $gray;

			}

			&-content {
				margin: -2.5rem auto 0 auto;
				max-width: 90%;
				background-color: $lighter-gray;
				padding: 1rem;
				text-align: center;
				text-decoration: none;
				position: relative;
				z-index: 2;
				flex: 1 1 auto;
				display: flex;
				flex-flow: column wrap;
				justify-content: space-between;
				&__heading {
					font-size: 1.25rem; /* 26/16 */
					font-weight: 700;
					color: $black;
					margin-bottom: 1.5rem;
					// hyphens: auto;

					@include below(940px) {
						font-size: 1rem;
					}
				}

				&__link {
					font-size: 0.875rem; /* 14/16 */
					font-weight: 700;
					text-transform: uppercase;
					text-decoration: underline;
					color: $green;
				}
			}
		}
	}
}
